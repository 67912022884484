// import { config as routesConfig } from './deployment/bin/routes'

export type AnnouncementsConfigBase = Record<
	string,
	{
		id: string

		type: 'notification' | 'overlay'
		cookieValueToHide: 'confirmed'
		cookieValueToTemporarilyHide: 'closed'
	}
>

export const config = {
	'eigenanteil-2025': {
		id: 'eigenanteil-2025',
		type: 'notification',
		cookieValueToHide: 'confirmed',
		cookieValueToTemporarilyHide: 'closed',
	},

	'hrmony-plus': {
		id: 'hrmony-plus',
		type: 'overlay',
		cookieValueToHide: 'confirmed',
		cookieValueToTemporarilyHide: 'closed',
	},
} as const satisfies AnnouncementsConfigBase

export type AnnouncementConfig = {
	id: (typeof config)[keyof typeof config]['id']
	type: 'notification' | 'overlay'
	cookieValueToHide: 'confirmed'
	cookieValueToTemporarilyHide?: 'closed'
}
