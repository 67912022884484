import 'vite/modulepreload-polyfill'
import '../styles/main.scss'

import {
	findAndInitAllComponents,
	initSentry,
} from '@hrmony/component-library/scripts'
import { logging } from '~scripts'
import { updateAndCleanUpDb } from '~scripts/helper/receipt-store/receipt-store'
import { config as routesConfig } from '../../deployment/bin/routes'

// Components
import '~components/AnnouncementNotification/AnnouncementNotification.component'
import '~components/AnnouncementOverlay/AnnouncementOverlay.component'
import '~components/Announcement/Announcement.component'
import '~components/DateNavigation/DateNavigation.component'
import '~components/EssenszuschussUploadOverlay/EssenszuschussUploadOverlay.component'
import '~components/Form/Form.component'
import '~components/ImagePreview/ImagePreview.component'
import '~components/LoadingOverlay/LoadingOverlay.component'
import '~components/Menu/Menu.component'
import '~components/NotificationService/NotificationService.component'
import '~components/OfflineBanner/OfflineBanner.component'
import '~components/Overlay/Overlay.component'
import '~components/Receipt/Receipt.component'
import '~components/ReceiptsList/ReceiptsList.component'
import '~components/VoucherCode/VoucherCode.component'
import '~components/SnapSlider/SnapSlider.component'
import '~components/TabNavigation/TabNavigation.component'

// Pages
import '~pages/sachbezug/SachbezugPartnerdetail/VoucherSelect.component'
import '~pages/sachbezug/SachbezugGutscheincodedetail/VoucherStateForm.component'
import '~pages/essenszuschuss/EssenszuschussUpload/EssenszuschussUpload.component'
import '~pages/hrmonyPlus/HrmonyPlusPartner/CopyAndRedirect.component'

// init
const registerServiceWorker = async (window: Window) => {
	// TODO: We used versioning for service worker at some point
	// We need to unregister all service worker with a hashed file name
	// to avoid conflicts
	// This can be removed after a while
	try {
		const registrations = await navigator.serviceWorker?.getRegistrations()

		for (const registration of registrations) {
			// Unregister all service worker with a hashed file name
			if (!registration.active?.scriptURL.match(/service-worker\.js$/)) {
				registration.unregister()
			}
		}
	} catch {
		//
	}

	const serviceWorkerScope = window.__DEV__
		? '/src/scripts/service-worker/'
		: '/'
	try {
		if (
			'serviceWorker' in window.navigator &&
			window._hrmonyConfig?.serviceWorker?.url
		) {
			await window.navigator.serviceWorker.register(
				`/${window._hrmonyConfig.serviceWorker.url}`,
				{
					scope: serviceWorkerScope,
				},
			)
		}
	} catch (error) {
		logging.logError({
			name: `Register Service Worker (${(error as Error).name})`,
			message: (error as Error).message,
		})
	}
}

initSentry('mba')

registerServiceWorker(window)

findAndInitAllComponents()

updateAndCleanUpDb()

window.addEventListener('appinstalled', () => {
	fetch(routesConfig.pwaInstalled.path, {
		headers: {
			'HX-Request': 'true',
		},
	})
})
