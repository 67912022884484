import type { BelegStatusReadModel } from '@hrmony/belegerfassung-pladddform'

type AllReceiptServerStates =
	BelegStatusReadModel['data']['items'][number]['status']

export const ReceiptDeclinedState = [
	'declined',
] as const satisfies Array<AllReceiptServerStates>
export const ReceiptClientStates = [
	'wirdHochgeladen',
	'hochgeladen',
	'fehlgeschlagen',
	'offline',
	'defekt',
] as const
export const ReceiptServerStates = [
	'captured',
	'open',
] as const satisfies Array<AllReceiptServerStates>
export const ReceiptNotDeclinedStates = [
	...ReceiptClientStates,
	...ReceiptServerStates,
] as const
