import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import * as cookies from '@hrmony/component-library/scripts/lib/cookies/cookies.js'
import { config as announcementsConfig } from '../../../../announcements.config'

export const identifier = 'menu'
const hrmonyPlusAnnouncementMenuId = 'hrmony-plus'

const MenuComponent: TComponentInit = (element) => {
	// const config: AnnouncementProps | Record<string, undefined> = JSON.parse(
	// 	element.getAttribute('data-component-config') ?? '{}',
	// )

	function init() {
		const hrmonyPlusCookie = cookies.get(hrmonyPlusAnnouncementMenuId)

		if (
			hrmonyPlusCookie !==
			announcementsConfig[hrmonyPlusAnnouncementMenuId]['cookieValueToHide']
		) {
			const badge = element.querySelector(
				`[data-menu-item-badge="${hrmonyPlusAnnouncementMenuId}"]`,
			)

			badge?.removeAttribute('hidden')
		}
	}

	function destroy() {}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, MenuComponent)

export default {
	identifier,
	MenuComponent,
}
