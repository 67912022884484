import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { emitter, logging } from '../../../scripts'
import { NotificationTypes } from '../Notification/Notification.shared'

export const identifier = 'notification-service'

const baseTimeToClose = 5000
const hasTemplateSupport = 'content' in document.createElement('template')

const initNotification = (notification: HTMLElement) => {
	const closeButton = notification.querySelector('[data-notification-close]')
	const contentLength =
		notification.querySelector('[data-notification-content]')?.textContent
			?.length ?? 0

	// After the first 150 characters,
	// add 1 second to the base time
	// for every 50 additional characters
	const remainingTime =
		baseTimeToClose + Math.ceil(Math.max(contentLength - 150, 0) / 50) * 1000
	let timerId: ReturnType<Window['setTimeout']> | undefined

	const closeNotification = () => {
		timerId && window.clearTimeout(timerId)
		timerId = undefined

		closeButton?.removeEventListener('click', closeNotification)
		notification.remove()
	}

	const startClosingTimer = () => {
		timerId = window.setTimeout(closeNotification, remainingTime)
	}

	closeButton?.addEventListener('click', closeNotification)

	startClosingTimer()

	return notification
}

const NotificationServiceComponent: TComponentInit = (element) => {
	const template = element.querySelector<HTMLTemplateElement>(
		'[data-notification-service-template]',
	)

	const onShow = (type: NotificationTypes, content: string, title?: string) => {
		const notificationElement = (
			template?.content.cloneNode(true) as HTMLElement | undefined
		)?.querySelector<HTMLElement>(`.notification--${type}`)

		if (!notificationElement) {
			return
		}

		const titleElement = notificationElement.querySelector(
			'[data-notification-title]',
		)
		const contentElement = notificationElement.querySelector(
			'[data-notification-content]',
		)

		if (titleElement && title) {
			titleElement.textContent = title
		}

		if (contentElement && content) {
			contentElement.textContent = content
		}

		initNotification(notificationElement)

		element.prepend(notificationElement)
	}

	const init = () => {
		if (!hasTemplateSupport) {
			logging.logError({ name: 'Template not supported!', message: '' })
			return
		}

		emitter.on('notification-service:show', onShow)
	}

	const destroy = () => {
		emitter.off('notification-service:show', onShow)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, NotificationServiceComponent)

export default {
	identifier,
	NotificationServiceComponent,
}
