import EventEmitter from 'events'
import TypedEmitter from 'typed-emitter'
import { NotificationTypes } from '~templates/components/Notification/Notification.shared'

type LoadingOverlayEvents = {
	'loading:start': (message?: string) => void
	'loading:stop': () => void
}

type ImagePreviewEvents = {
	'imagePreview:change': (files: FileList | null) => void
}

type EssenszuschussUploadEvents = {
	'essenszuschussUpload:file-change': (files: FileList | null) => void
}

type EssenszuschussUploadOverlayEvents = {
	'essenszuschussUploadOverlay:form-loaded': (responseText: string) => void
}

type ReceiptStoreEvents = {
	'receipt-store:added': (receiptId: string) => void
	'receipt-store:updated': (receiptId: string) => void
	'receipt-store:deleted': (receiptId: string) => void
	'receipt-store:deleted-multiple': (receiptId: string[]) => void
}

type NotificationServiceEvents = {
	'notification-service:show': (
		type: NotificationTypes,
		content: string,
		title?: string,
	) => void
}

export type EmitterEvents = LoadingOverlayEvents &
	ImagePreviewEvents &
	EssenszuschussUploadEvents &
	EssenszuschussUploadOverlayEvents &
	ReceiptStoreEvents &
	NotificationServiceEvents

export const emitter = new EventEmitter() as TypedEmitter<EmitterEvents>
