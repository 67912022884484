import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { emitter } from '../../../../scripts'

export const identifier = 'voucher-state-form'

const VoucherStateFormComponent: TComponentInit = (element) => {
	const form = element.querySelector<HTMLFormElement>('form')
	// const dialog = element.querySelector<HTMLDialogElement>('dialog')
	const restguthabenInput = element.querySelector<HTMLInputElement>(
		'[name="restguthaben"]',
	)

	const onSubmit = async (event: Event) => {
		if (!form) {
			return
		}

		event.preventDefault()
		emitter.emit('loading:start')
		form.submit()
	}

	const onChange = (event: Event) => {
		if (
			(event.target as HTMLInputElement | null)?.name === 'verbrauchsstatus'
		) {
			onSubmit(event)
		}
	}

	const onInput = () => {
		if (!form || !restguthabenInput) {
			return
		}

		const formData = new FormData(form)

		restguthabenInput.value =
			(formData.get('restguthaben-sync') as string | null) || ''
	}

	const init = () => {
		form?.addEventListener('change', onChange)
		form?.addEventListener('input', onInput)
		form?.addEventListener('submit', onSubmit)
	}

	function destroy() {
		form?.removeEventListener('change', onChange)
		form?.removeEventListener('input', onInput)
		form?.removeEventListener('submit', onSubmit)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, VoucherStateFormComponent)

export default {
	identifier,
	VoucherStateFormComponent,
}
