import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import * as cookies from '@hrmony/component-library/scripts/lib/cookies/cookies.js'
import {
	AnnouncementConfig,
	config as announcementsConfig,
} from '../../../../announcements.config'

export const identifier = 'announcement-notification'

const AnnouncementNotificationComponent: TComponentInit = (element) => {
	const config: AnnouncementConfig | Record<string, undefined> = JSON.parse(
		element.getAttribute('data-component-config') ?? '{}',
	)

	const configId = config.id
	const closeButton = element.querySelector<HTMLButtonElement>(
		'.notification__close',
	)
	const relatedCookie = configId && cookies.get(configId)

	const onClickClose = () => {
		if (!configId) {
			return
		}

		cookies.set(configId, announcementsConfig[configId].cookieValueToHide, 365)
		element.setAttribute('hidden', '')
	}

	function init() {
		closeButton?.addEventListener('click', onClickClose)

		relatedCookie === null && element.removeAttribute('hidden')
	}

	function destroy() {
		closeButton?.removeEventListener('click', onClickClose)

		element.setAttribute('hidden', '')
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, AnnouncementNotificationComponent)

export default {
	identifier,
	AnnouncementNotificationComponent,
}
