import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import * as cookies from '@hrmony/component-library/scripts/lib/cookies/cookies.js'
import {
	AnnouncementConfig,
	config as announcementsConfig,
} from '../../../../announcements.config'
import dialogPolyfill from 'dialog-polyfill'

export const identifier = 'announcement-overlay'

const AnnouncementOverlayComponent: TComponentInit = (element) => {
	const config: AnnouncementConfig | Record<string, undefined> = JSON.parse(
		element.getAttribute('data-component-config') ?? '{}',
	)

	const configId = config.id
	const dialog = element.querySelector<HTMLDialogElement>('dialog')
	const announcementConfig = configId && announcementsConfig[configId]

	function open() {
		if (announcementConfig?.type !== 'overlay' || !configId) {
			return
		}

		cookies.set(configId, announcementConfig.cookieValueToTemporarilyHide, 1)

		// TODO expose open event in overlay component
		dialog && !dialog.open && dialog?.showModal()
	}

	function init() {
		if (!configId || !dialog) {
			return
		}

		dialogPolyfill.registerDialog(dialog)

		const relatedCookie = cookies.get(configId)

		element.removeAttribute('hidden')

		if (!relatedCookie) {
			open()
		}
	}

	function destroy() {
		element.setAttribute('hidden', '')
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, AnnouncementOverlayComponent)

export default {
	identifier,
	AnnouncementOverlayComponent,
}
