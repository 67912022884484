import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import * as cookies from '@hrmony/component-library/scripts/lib/cookies/cookies.js'
import { config as announcementsConfig } from '../../../../announcements.config'
import { AnnouncementConfig } from './Announcement.shared'

export type AnnouncementProps = AnnouncementConfig

export const identifier = 'announcement'

const AnnouncementComponent: TComponentInit = (element) => {
	const config: AnnouncementProps = JSON.parse(
		element.getAttribute('data-component-config')!,
	)

	function init() {
		// Ensure the Cookie will be always,
		// since we can't set cookies without expiration date
		if (config.setCookie === 'refresh') {
			for (const announcementConfig of Object.values(announcementsConfig)) {
				const relatedCookie = cookies.get(announcementConfig.id)

				relatedCookie === announcementConfig.cookieValueToHide &&
					cookies.set(announcementConfig.id, relatedCookie, 365)
			}
		} else if (config.id) {
			let cookieValueToSet:
				| 'cookieValueToHide'
				| 'cookieValueToTemporarilyHide' = 'cookieValueToHide'

			if (config.setCookie === 'hide') {
				cookieValueToSet = 'cookieValueToHide'
			}

			if (config.setCookie === 'hide-temporarily') {
				cookieValueToSet = 'cookieValueToTemporarilyHide'
			}

			cookieValueToSet

			const cookieValue = announcementsConfig?.[config.id]?.[cookieValueToSet]

			cookieValue && cookies.set(config.id, cookieValue, 365)
		}
	}

	function destroy() {}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, AnnouncementComponent)

export default {
	identifier,
	AnnouncementComponent,
}
