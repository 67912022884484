import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { logging, receiptStore, htmlEntities, emitter } from '../../../scripts'
import { EssenszuschussUploadFormConfig } from '../../pages/essenszuschuss/EssenszuschussUpload/EssenszuschussUpload.shared'
import { arrayBufferToBlob } from '../../../scripts/helper/file-handling/file-handling'

export const identifier = 'receipt'

// const hasTemplateSupport = 'content' in document.createElement('template')

const ReceiptComponent: TComponentInit = (element) => {
	const receiptId = element.getAttribute('data-receipt-id')
	const receiptState = element.getAttribute('data-receipt-state')
	const uploadErrorMessage = element.getAttribute('data-receipt-error-message')

	const removeButton = element.querySelector('[data-receipt-remove]')
	const uploadButton = element.querySelector('[data-receipt-upload]')

	// console.log({ receiptId, receiptState })
	const onDelete = async () => {
		if (!receiptId) {
			return
		}

		await receiptStore.deleteEntry(receiptId)

		emitter.emit('notification-service:show', 'success', 'Beleg entfernt!')
	}

	const onUpload = async () => {
		if (!receiptId) {
			return
		}

		emitter.emit('loading:start')

		const formData = new FormData()

		try {
			const receipt = await receiptStore.getEntry(receiptId)
			const presignedUrlResponse = await fetch(
				`${window._hrmonyConfig?.upload?.url}?configOnly=true`,
				{
					headers: {
						'HX-Request': 'true',
					},
				},
			)

			if (!presignedUrlResponse.ok) {
				throw new Error('Fetch presigned URL failed')
			}

			/**
			 * {
			 * 	"url": "/essenszuschuss/upload",
			 * 	"fields": {
			 * 		"key": "file_uploads/test/path/d980afb8-0bd2-4eb4-af84-5f564bece8a5",
			 * 		[...]
			 * 	}
			 * }
			 */
			const uploadConfig = JSON.parse(
				htmlEntities.decode(await presignedUrlResponse.text()),
			) as EssenszuschussUploadFormConfig
			const splittedUrl = uploadConfig.fields.key?.toString().split('/')
			const newReceiptId = splittedUrl[splittedUrl.length - 1]

			if (!newReceiptId) {
				throw new Error(`Couldn't find new receipt id.`)
			}

			for (const [key, value] of Object.entries(uploadConfig.fields)) {
				if (key === 'file') {
					return
				}

				formData.set(key, value)
			}

			if (!receipt?.files[0].file) {
				receiptStore.updateEntry(receiptId, { state: 'defekt' })

				throw new Error('File defect.')
			}

			formData.set(
				'file',
				arrayBufferToBlob(receipt?.files[0].file, receipt?.files[0].type),
			)

			const response = await fetch(uploadConfig.url, {
				method: 'POST',
				headers: {
					'HX-Request': 'true',
				},
				body: formData,
				credentials: 'include',
				redirect: 'manual',
			})

			// Successful uploaded
			// Try to save receipt in indexedDb and redirect to "InPruefung"
			if (response.status === 0) {
				console.log('ok', response)
				// await receiptStore.addEntry({
				// 	id: receiptId,
				// 	dateTime: new Date().toISOString(),
				// 	state: 'hochgeladen',
				// 	files,
				// })
			} else if (!response.ok) {
				throw new Error('Upload failed.')
			}

			// const responseText = await response.text()

			// console.log(responseText)

			// TODO update receipt id
			// => remove old receipt
			// => add new receipt
			await receiptStore.deleteEntry(receiptId)
			await receiptStore.addEntry({
				...receipt,
				id: newReceiptId,
				state: 'hochgeladen',
			})

			const url = new URL(window.location.href)
			url.searchParams.set('success', 'true')

			window.location.href = url.href
		} catch (error) {
			logging.logError({
				name: 'Upload Retry',
				message: (error as Error).message,
			})

			try {
				const receipt = await receiptStore.getEntry(receiptId)

				if (receipt) {
					await receiptStore.updateEntry(receipt?.id, {
						state: 'fehlgeschlagen',
					})
				}
			} catch {
				///
			}

			emitter.emit(
				'notification-service:show',
				'error',
				uploadErrorMessage || '',
			)
		} finally {
			emitter.emit('loading:stop')
		}
	}

	const init = async () => {
		removeButton?.addEventListener('click', onDelete)
		uploadButton?.addEventListener('click', onUpload)

		if (['fehlgeschlagen', 'offline', 'defekt'].includes(receiptState || '')) {
			removeButton?.removeAttribute('hidden')
		}
		console.log(
			{ element, uploadButton },
			receiptState,
			['fehlgeschlagen', 'offline'].includes(receiptState || ''),
		)
		if (['fehlgeschlagen', 'offline'].includes(receiptState || '')) {
			uploadButton?.removeAttribute('hidden')
		}
	}

	function destroy() {
		removeButton?.removeEventListener('click', onDelete)
		uploadButton?.removeEventListener('click', onUpload)

		removeButton?.setAttribute('hidden', '')
		uploadButton?.setAttribute('hidden', '')
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, ReceiptComponent)

export default {
	identifier,
	ReceiptComponent,
}
