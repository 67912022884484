import {
	registerComponent,
	TComponentInit,
} from '@hrmony/component-library/scripts'
import { emitter } from '../../../scripts'

const identifier = 'voucher-code'
const activeModifier = 'active'

const VoucherCodeComponent: TComponentInit = (element) => {
	const copyButton = element.querySelector('[data-copy-button]')
	const code = element.getAttribute('data-code')
	const successMessage = element.getAttribute('data-success-message') ?? ''

	if (navigator.clipboard && code) {
		element.classList.add(`${identifier}--${activeModifier}`)
	}

	const copyToClipboard = () => {
		navigator.clipboard.writeText(code ?? '')

		emitter.emit('notification-service:show', 'success', successMessage)
	}

	return {
		element,
		init: () => {
			copyButton?.addEventListener('click', copyToClipboard)
		},
		destroy: () => {
			copyButton?.removeEventListener('click', copyToClipboard)
			element.classList.remove(`${identifier}--${activeModifier}`)
		},
	}
}

registerComponent(identifier, VoucherCodeComponent)

export default {
	identifier,
	VoucherCodeComponent,
}
