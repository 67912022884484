import {
	registerComponent,
	TComponentInit,
	findAndInitAllComponents,
	findAndDestroyAllComponents,
} from '@hrmony/component-library/scripts'
import { getFormattedDateTime } from '@hrmony/component-library/shared-helper'
// import type { EssenszuschussUploadFormConfig } from '../../pages/essenszuschuss/EssenszuschussUpload/EssenszuschussUpload.shared'
import { emitter, receiptStore } from '../../../scripts'
import { arrayBufferToBlob } from '../../../scripts/helper/file-handling/file-handling'
import type { ReceiptsListConfig } from './ReceiptsList.shared.d'

// import { emitter } from '../../../scripts'

export const identifier = 'receipts-list'

const hasTemplateSupport = 'content' in document.createElement('template')

const ReceiptsListComponent: TComponentInit = (element) => {
	const config: ReceiptsListConfig | Record<string, undefined> = JSON.parse(
		element.getAttribute('data-component-config') ?? '{}',
	)
	const receiptList = element.querySelector('[data-receipts-list]')
	const receiptsListItems = [...element.querySelectorAll('[data-receipt-id]')]
	const receiptCounter = element.querySelector('[data-receipts-counter]')
	const initialReceiptCount = parseInt(
		receiptCounter?.getAttribute('data-receipts-counter') ?? '0',
	)
	const uploadedReceiptIds = receiptsListItems.map((item) =>
		item.getAttribute('data-receipt-id'),
	)
	const template = element.querySelector<HTMLTemplateElement>('[data-template]')
	let localReceipts: Awaited<ReturnType<typeof receiptStore.getAllEntries>> = []

	const removeLocalReceipts = () => {
		const localReceiptListItems = receiptList?.querySelectorAll(
			'[data-local-receipt-item]',
		)

		if (!receiptList || !localReceiptListItems) {
			return
		}

		localReceiptListItems.forEach((element) => {
			element.remove()
		})
	}

	const addLocalReceipts = async () => {
		localReceipts = await receiptStore.getAllEntries()
		// Only use receipts not already rendered on page load
		localReceipts = localReceipts
			.filter(([id]) => !uploadedReceiptIds.includes(id))
			.sort(([, receiptA], [, receiptB]) => {
				const dateA = new Date(receiptA.dateTime)
				const dateB = new Date(receiptB.dateTime)

				return dateA.getTime() - dateB.getTime()
			})

		localReceipts.forEach(([id, receipt]) => {
			const imgSrcs: string[] = []
			const listItemElement = (
				template?.content.cloneNode(true) as HTMLElement | undefined
			)?.querySelector<HTMLLIElement>('li')
			const receiptElement = (
				template?.content.cloneNode(true) as HTMLElement | undefined
			)?.querySelector<HTMLElement>('.receipt')
			const receiptImg = receiptElement?.querySelector('img')
			const receiptTitle = receiptElement?.querySelector('[data-receipt-date]')

			if (
				!listItemElement ||
				!receiptElement ||
				!receiptList ||
				!hasTemplateSupport
			) {
				return
			}

			receipt.files.forEach((file) => {
				if (!file.file || !file.type.match('image/')) {
					return
				}

				const fileBlob = arrayBufferToBlob(file.file, file.type)
				imgSrcs.push(window.URL.createObjectURL(fileBlob))
			})

			receiptElement.setAttribute('data-receipt-id', id)
			receiptElement.setAttribute('data-receipt-state', receipt.state)

			if (receiptImg && imgSrcs[0]) {
				receiptImg.src = imgSrcs[0]
			} else {
				receiptImg?.remove()
				receiptElement.classList.add('receipt--no-preview')
			}

			if (receiptTitle) {
				const formattedDateTime = getFormattedDateTime(
					config.locale || 'de',
					receipt.dateTime,
				)

				receiptTitle.textContent = (config?.receiptUploadTimeString || '')
					.replace('{day}', formattedDateTime?.shortWeekDay || '')
					.replace('{date}', formattedDateTime?.date || '')
					.replace('{time}', formattedDateTime?.time || '')
			}

			listItemElement.setAttribute('data-local-receipt-item', id)
			listItemElement.append(receiptElement)

			receiptList.prepend(listItemElement)

			element.classList.remove(`${identifier}--empty`)
		})
	}

	const renderLocalReceipts = async () => {
		findAndDestroyAllComponents(element)

		removeLocalReceipts()
		await addLocalReceipts()

		findAndInitAllComponents(element)
	}

	const updateReceiptCount = () => {
		if (!receiptCounter) {
			return
		}

		receiptCounter.textContent = `${initialReceiptCount + localReceipts.length}`
	}

	const onReceiptDeleted = (receiptId: string) => {
		const receiptListItem = receiptList?.querySelector(
			`[data-local-receipt-item="${receiptId}"]`,
		)

		receiptListItem?.remove()

		if (receiptList?.childNodes.length === 0) {
			element.classList.add(`${identifier}--empty`)
		}

		updateReceiptCount()
	}

	// const onReceiptAdded = async (receiptId: string) => {
	// 	// TODO only add the new receipt item to the beginning of the list
	// 	console.log(receiptId)
	// 	await renderLocalReceipts()
	//
	// 	updateReceiptCount()
	// }

	const init = async () => {
		emitter.addListener('receipt-store:deleted', onReceiptDeleted)
		// emitter.addListener('receipt-store:added', onReceiptAdded)

		await renderLocalReceipts()
		updateReceiptCount()

		const url = new URL(window.location.href)

		if (url.searchParams.has('success', 'true')) {
			emitter.emit(
				'notification-service:show',
				'success',
				config.uploadSuccessMessage || '',
			)

			url.searchParams.delete('success')
			window.history.replaceState(null, '', url.href)
		}

		if (url.searchParams.has('error', 'true')) {
			emitter.emit(
				'notification-service:show',
				'error',
				config.uploadErrorMessage || '',
			)

			url.searchParams.delete('error')
			window.history.replaceState(null, '', url.href)
		}
	}

	function destroy() {
		emitter.removeListener('receipt-store:deleted', onReceiptDeleted)
		// emitter.removeListener('receipt-store:added', onReceiptAdded)
	}

	return {
		element,
		init,
		destroy,
	}
}

registerComponent(identifier, ReceiptsListComponent)

export default {
	identifier,
	ReceiptsListComponent,
}
