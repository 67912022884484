import type { RoutesConfig } from '@hrmony/component-library/helper'

export const config = {
	base: {
		path: '/',
		parameters: [],
	},
	startUpRedirect: {
		path: '/start',
		parameters: [],
	},
	// Settings
	settings: {
		path: '/einstellungen',
		parameters: [],
	},
	nameAendern: {
		path: '/einstellungen/name-aendern',
		parameters: [],
	},
	emailAendern: {
		path: '/einstellungen/email-aendern',
		parameters: [],
	},
	passwortAendern: {
		path: '/einstellungen/passwort-aendern',
		parameters: [],
	},
	spracheAendern: {
		path: '/einstellungen/sprache-aendern',
		parameters: [],
	},
	/**
	 * There is legacy code in the log out handler that always redirects to /authenticate after logging out.
	 */
	login: {
		path: '/authenticate',
		parameters: [],
	},
	logout: {
		path: '/logout',
		parameters: [],
	},
	refresh: {
		path: '/refresh',
		parameters: [],
	},
	passwortVergessen: {
		path: '/passwort-vergessen',
		parameters: [],
	},
	passwortZuruecksetzen: {
		path: '/passwort-zuruecksetzen',
		parameters: [],
	},
	nutzungsbedingungen: {
		path: '/nutzungsbedingungen',
		parameters: [],
	},
	aktivierungsstatusMba: {
		path: '/aktivierungsstatus/{organisationsId}',
		parameters: ['organisationsId'],
	},
	pwaInstalled: {
		path: '/pwa-installiert',
		parameters: [],
	},

	// Benefit
	benefitUebersicht: {
		path: '/benefits',
		parameters: [],
	},

	// Essenszuschuss
	essenszuschussAgb: {
		path: '/agb-essenszuschuss',
		parameters: [],
	},
	essenszuschussAktuelleUebersicht: {
		path: '/essenszuschuss',
		parameters: [],
	},
	essenszuschussUebersicht: {
		path: '/essenszuschuss/{monat}',
		parameters: ['monat'],
	},
	essenszuschussTag: {
		path: '/essenszuschuss/tageserstattung/{tag}',
		parameters: ['tag'],
	},
	essenszuschussInPruefung: {
		path: '/essenszuschuss/in-pruefung',
		parameters: [],
	},
	essenszuschussAbgelehnt: {
		path: '/essenszuschuss/abgelehnt',
		parameters: [],
	},
	essenszuschussUpload: {
		path: '/essenszuschuss/upload',
		parameters: [],
	},
	essenszuschussBelegstatus: {
		path: '/essenszuschuss/beleg/{belegId}/status',
		parameters: ['belegId'],
	},

	// Sachbezug
	sachbezugAgbBestaetigen: {
		path: '/agb-sachbezug/bestaetigen',
		parameters: [],
	},
	sachbezugAgb: {
		path: '/agb-sachbezug',
		parameters: [],
	},
	sachbezugUebersicht: {
		path: '/sachbezug',
		parameters: [],
	},
	sachbezugHistorie: {
		path: '/sachbezug/historie',
		parameters: [],
	},
	sachbezugAktuelleGutscheinauswahl: {
		path: '/sachbezug/aktuelle-gutscheinauswahl',
		parameters: [],
	},
	sachbezugPartneruebersicht: {
		path: '/sachbezug/uebersicht',
		parameters: [],
	},
	sachbezugPartnerdetail: {
		path: '/sachbezug/partner/{partnerId}',
		parameters: ['partnerId'],
	},
	sachbezugGutscheincodedetail: {
		path: '/sachbezug/gutschein/{gutscheincodeId}',
		parameters: ['gutscheincodeId'],
	},

	// Onboarding
	homeScreenInstallationStart: {
		path: '/willkommen',
		parameters: [],
	},
	homeScreenInstallation: {
		path: '/willkommen/homescreen-installation',
		parameters: [],
	},

	// HrmonyPlus
	hrmonyPlusUebersicht: {
		path: '/hrmony-plus',
		parameters: [],
	},
	hrmonyPlusPartner: {
		path: '/hrmony-plus/{partnerId}',
		parameters: ['partnerId'],
	},
	hrmonyPlusPartnerRedirect: {
		// This is a placeholder to use the tracking workaround
		// which is not working with path parameters right now.
		path: '/hrmony-plus/partner/partnerId', // '/hrmony-plus/partner/{partnerId}'
		parameters: [
			// 'partnerId'
		],
	},
	hrmonyPlusPartnerSovendusRedirect: {
		path: '/hrmony-plus/partner/sovendus',
		parameters: [],
	},
	hrmonyPlusPartnerTaxfixRedirect: {
		path: '/hrmony-plus/partner/taxfix',
		parameters: [],
	},
	hrmonyPlusPartnerFinanzguruRedirect: {
		path: '/hrmony-plus/partner/finanzguru',
		parameters: [],
	},
	hrmonyPlusPartnerUscRedirect: {
		path: '/hrmony-plus/partner/usc',
		parameters: [],
	},
	hrmonyPlusPartnerParqetRedirect: {
		path: '/hrmony-plus/partner/parqet',
		parameters: [],
	},
	hrmonyPlusPartnerGymondoRedirect: {
		path: '/hrmony-plus/partner/gymondo',
		parameters: [],
	},
	hrmonyPlusPartnerBookbeatRedirect: {
		path: '/hrmony-plus/partner/bookbeat',
		parameters: [],
	},
} as const satisfies RoutesConfig
